<template>
  <v-autocomplete
    v-model="selectedSeminars"
    :items="availableSeminars"
    :loading="isSeminarsLoading"
    :search-input.sync="seminarApiSearch"
    v-on:input="onSeminarSelected"
    :label="label ?? $t('Seminar')"
    multiple
    no-filter
    hide-no-data
    hide-selected
    outlined
    chips
    deletable-chips
    :required="required"
    :disabled="disabled"
    :error-messages="errorMessages"
    :item-text="item => `(${item.number}) ${item.name}`"
    item-value="@id"
  />
</template>

<script>
  import SeminarMixin from '@/mixins/SeminarMixin';
  import { mapActions } from 'vuex';
  import { formatDateTime } from '@/utils/dates';

  export default {
    name: 'SeminarSearch',
    mixins: [SeminarMixin],
    props: {
      value: {
        type: Array,
        required: false,
        default: () => []
      },
      onSelected: {
        type: Function,
        required: true,
      },
      label: {
        type: String,
        required: false,
        default: () => null
      },
      required: {
        type: Boolean,
        required: false,
        default: () => false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: () => false
      },
      errorMessages: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        selectedSeminars: null,
        seminarApiSearch: null,
        isSeminarsLoading: false,
        controller: new AbortController(),
        seminarSearchResults: [],
        test: null,
      };
    },
    computed: {
      propertySeminarItems() {
        return this.value;
      },
      availableSeminars() {
        return this.seminarSearchResults;
      },
      selectedSeminarObjects() {
        let seminarObjects = [];
        if (this.propertySeminarItems && this.propertySeminarItems.length > 0) {
          const seminarIds = this.propertySeminarItems.map((seminarItem) => seminarItem['@id']);
          seminarObjects = this.resolveSeminars(seminarIds);
        }
        return seminarObjects;
      },
    },
    methods: {
      formatDateTime,
      ...mapActions('seminar', {
        getSeminars: 'fetchAll'
      }),
      onSeminarSelected(seminar) {
        this.onSelected(seminar);
      }
    },
    watch: {
      seminarApiSearch(val) {
        if (val.length < 3) {
          this.isSeminarsLoading = false;
          return;
        }
        this.isSeminarLoading = true;
        this.controller.abort();
        this.controller = new AbortController();
        const signal = this.controller.signal;
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

        const params = {
          page: 1,
          itemsPerPage: 30,
          'seminarConfig.enabled': 1,
          'seminarConfig.confirmed': 0,
          'seminarConfig.cancelled': 0,
          'order[timeSlot.start]': 'DESC',
          'timeSlot.start[after]': yesterday.toISOString(),
          search: val,
          signal: signal
        };
        this.getSeminars(params)
          .then((result) => {
            this.seminarSearchResults = result ? [...result, ...this.selectedSeminarObjects] : this.selectedSeminarObjects;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isSeminarsLoading = false;
          });
      },
      propertySeminarItems() {
        this.seminarSearchResults = this.selectedSeminarObjects;
        this.selectedSeminars = this.seminarSearchResults;
      }
    },
    mounted() {
      this.seminarSearchResults = this.selectedSeminarObjects;
      this.selectedSeminars = this.seminarSearchResults;
    }
  };
</script>
